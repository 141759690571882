import {each, findWhere, isEmpty} from "underscore";

export const processExactKeyValuePairSettings = (
  organizationSettings,
  settings,
) => {
  if (isEmpty(organizationSettings)) return {};

  const keyValuePairSettings = {};
  each(settings, (key) => {
    const setting = findWhere(organizationSettings, {key});
    keyValuePairSettings[key] = setting?.value;
    keyValuePairSettings[`${key}Setting`] = setting;
  });
  return keyValuePairSettings;
};

export const processTrueFalseSettings = (organizationSettings, settings) => {
  if (isEmpty(organizationSettings)) return {};

  const trueFalseSettings = {};
  each(settings, (key) => {
    const setting = findWhere(organizationSettings, {key});
    trueFalseSettings[key] =
      setting?.value === "true" || setting?.value === true;
    trueFalseSettings[`${key}Setting`] = setting;
  });
  return trueFalseSettings;
};
