import React from "react";
import {Box, InputAdornment} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import axios from "axios";
import CustomTextField from "../../forms/CustomTextField";
import {useAuthStyles} from "../AuthStyles";
import {SubmitButton} from "../../buttons";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {mfaSchema} from "../schemas";
import {useAuth} from "../Context/AuthProvider";
import MFAContainer from "./MFAContainer";
import {isEmpty} from "underscore";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const MFAChallengeForm = () => {
  const {classes} = useAuthStyles();
  const {
    authSession,
    setAuthSession,
    setErrorMessage,
    setIsAuthLoading,
    isAuthLoading,
    orgName,
  } = useAuth();
  const formMethods = useForm({resolver: yupResolver(mfaSchema)});

  const submitMfa = async (data) => {
    setIsAuthLoading(true);
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/login/mfa`, {
        orgName,
        ChallengeName: "SMS_MFA",
        ChallengeResponses: {
          USERNAME: authSession.username,
          SMS_MFA_CODE: data.mfaCode,
        },
        Session: authSession.Session,
      });
      if (response.status === 200 && !isEmpty(response?.data)) {
        setAuthSession({
          ...response.data,
          rememberMe: authSession.rememberMe,
          username: authSession.username,
        });
        setErrorMessage("");
      } else {
        setErrorMessage("Login unsuccessful.");
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ??
          error?.message ??
          JSON.stringify(error),
      );
    } finally {
      setIsAuthLoading(false);
    }
  };

  return (
    <MFAContainer>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(submitMfa)}
          className={classes.form}
        >
          <CustomTextField
            name="mfaCode"
            label="One-Time Code"
            required
            autoComplete="off"
            autoFocus
            errorText="Valid One-Time Code is required"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon
                    className={classes.adornmentIcon}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Box mt={3}>
            <SubmitButton isLoading={isAuthLoading}>Submit</SubmitButton>
          </Box>
        </form>
      </FormProvider>
    </MFAContainer>
  );
};

export default MFAChallengeForm;
