import React, {useState, useMemo, useEffect} from "react";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {
  Button,
  Popper,
  Fade,
  Paper,
  Typography,
  Box,
  LinearProgress,
  ClickAwayListener,
  List,
  ListItem,
  Icon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import {useOrgSettings} from "../../context/OrgSettingsProvider";
import {useTopbarStyles} from "./topbarStyles";
import {useTour} from "../tour/Context/TourProvider";
import {useUser} from "../user/Context/UserProvider";
import {filter, isEmpty, reduce} from "underscore";

const GettingStarted = () => {
  const orgSettings = useOrgSettings();
  const {classes} = useTopbarStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [tourToLoad, setTourToLoad] = useState(null);
  const [startItems, setStartItems] = useState([]);
  const history = useHistory();
  const {setTourId, tourId} = useTour();
  const {user} = useUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) return;
    setOpen(false);
  };

  const launchTour = (url, nextTourId) => {
    if (tourId !== null) {
      setTourId(null);
      setTourToLoad(nextTourId);
    } else {
      setTourId(nextTourId);
      setOpen(false);
    }
    history.push(url);
  };

  useEffect(() => {
    if (tourId !== null || tourToLoad === null) return;
    setTourId(tourToLoad);
    setOpen(false);
    setTourToLoad(null);
  }, [tourId, tourToLoad]);

  const tourSectionComplete = (tourId, section) => {
    const storage = user?.metadata?.tours;

    if (storage) {
      const completedProfileSections = storage?.[tourId]?.completedSections;
      if (completedProfileSections || !isEmpty(completedProfileSections)) {
        return completedProfileSections.includes(section);
      }
    }

    return false;
  };

  const startItemsConfig = [
    {
      id: 1,
      completed: tourSectionComplete("profile", "profile"),
      title: "Configure User Profile",
      details: "Customize your BreachRx experience",
      url: "/userProfile",
      tourId: "profile",
      duration: {minutes: 5},
      admin: false,
    },
    {
      id: 2,
      completed: tourSectionComplete("orgSettings", "securityTour"),
      settingRequired: "orgFullname",
      title: "Customize Organization Settings",
      details: "Tailor BreachRx to your organization",
      url: "/organization/general",
      tourId: "orgSettings",
      duration: {minutes: 7},
      admin: true,
    },
    {
      id: 3,
      completed: tourSectionComplete("compliance", "complianceTour"),
      title: "Select Compliance Frameworks",
      details: "Align to external and voluntary compliance frameworks",
      url: "/organization/compliance",
      tourId: "compliance",
      duration: {minutes: 8},
      admin: true,
    },
    {
      id: 4,
      completed: tourSectionComplete("attributes", "attributesTour"),
      title: "Set Default Workflow Settings",
      details:
        "Preselect attributes and workflow settings for your organization",
      url: "/organization/attributes",
      tourId: "attributes",
      duration: {minutes: 10},
      admin: true,
    },
    {
      id: 6,
      completed: tourSectionComplete("notifications", "notificationsTour"),
      title: "Customize Notification Settings",
      details: "Configure when and how users receieve notifications",
      url: "/notifications",
      tourId: "notifications",
      duration: {minutes: 5},
      admin: true,
    },
    {
      id: 5,
      completed: tourSectionComplete("obligation", "obligationTour"),
      title: "Update Local Law Enforcement Obligation",
      details:
        "Update the contact information for the local law enforcement obligation",
      url: "/obligations/list",
      tourId: "obligation",
      duration: {minutes: 8},
      admin: true,
    },
  ];

  useEffect(() => {
    if (orgSettings?.settingsLoading) return;
    const items = [];

    for (const config of startItemsConfig) {
      if (config.settingRequired && config.completed) {
        const setting = orgSettings?.[config.settingRequired];
        if (isEmpty(setting)) {
          items.push({...config, completed: false});
          continue;
        }
      }

      items.push({...config});
    }

    setStartItems(items);
  }, [orgSettings?.settingsLoading, user]);

  const filteredItems = useMemo(
    () =>
      user.hasAdminRole ? startItems : (
        filter(startItems, (item) => !item.admin)
      ),
    [startItems],
  );

  const completedPercentage = useMemo(
    () =>
      Math.floor(
        (filter(filteredItems, (item) => item.completed).length /
          filteredItems.length) *
          100,
      ),
    [filteredItems],
  );

  const estimatedTime = useMemo(() => {
    const totalTime = reduce(
      filteredItems,
      (memo, step) => {
        return !step.completed ? memo.add(dayjs.duration(step.duration)) : memo;
      },
      dayjs.duration(0),
    );

    return totalTime.asSeconds() === 0 ? null : totalTime.humanize();
  }, [filteredItems]);

  const completed = filter(filteredItems, (item) => item.completed).length;
  const total = filteredItems.length;

  if (orgSettings?.settingsLoading) return null;
  if (completed === total) {
    return (
      <span
        id="getting-started"
        data-testid="getting-started-complete"
        style={{display: "none"}}
      />
    );
  }

  const sortedItems = filteredItems.sort(
    (a, b) => Number(a.completed) - Number(b.completed),
  );

  const listItems = sortedItems.map((item) => (
    <ListItem
      key={item.id}
      secondaryAction={
        <Icon edge="end" aria-label="completed">
          {item.completed ?
            <CheckCircleIcon color="success" /> :
            <RadioButtonUncheckedOutlinedIcon />}
        </Icon>
      }
    >
      <ListItemButton
        className={classes.startedItemButton}
        onClick={() => launchTour(item.url, item.tourId)}
      >
        <ArrowForwardIosIcon fontSize="small" sx={{paddingRight: 1}} />
        <ListItemText
          primary={
            <>
              <Typography
                display="inline"
                className={
                  item.completed ?
                    classes.completedItem :
                    classes.incompleteItem
                }
              >
                {item.title}
              </Typography>
              {!item.completed && (
                <Typography variant="caption">
                  {" "}
                  - <i>{dayjs.duration(item.duration).humanize()}</i>
                </Typography>
              )}
            </>
          }
          secondary={
            <Typography
              variant="caption"
              className={item.completed ? classes.completedItemCaption : ""}
            >
              {item.details}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  ));

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="grey"
        className={classes.gettingStartedButton}
        id="getting-started"
      >
        <Typography variant="caption" className={classes.gettingStartedText}>
          Getting Started {" | "}
          {completedPercentage}%
        </Typography>
        <Box sx={{width: "100%"}}>
          <LinearProgress
            variant="determinate"
            value={completedPercentage}
            className={classes.progressBar}
          />
        </Box>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        sx={{zIndex: 1500, pt: 1}}
      >
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.gettingStartedPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box className={classes.startedContainer}>
                  <Box pt={2} pb={1} pl={4}>
                    <Typography
                      variant="body1"
                      className={classes.startedTitle}
                    >
                      Getting Started with BreachRx
                    </Typography>
                    <Typography variant="caption">
                      {completed} of {total} complete
                      {estimatedTime && ` (about ${estimatedTime} total left)`}
                    </Typography>
                  </Box>
                  <div className={classes.startedListWrapper}>
                    <List dense>{listItems}</List>
                  </div>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default GettingStarted;
