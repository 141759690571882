export const buildCSP = () => {
  const backendURL = process.env.GRAPHQL_API;
  const authURL = process.env.AUTH_ENDPOINT;

  const csp = {
    "base-uri": ["'self'"],
    "default-src": ["'self'"],
    "script-src": [
      "'self'",
      "'unsafe-eval'",
      "https://*.amplitude.com",
      "https://cdn.logrocket.io",
      "https://cdn.lr-ingest.com",
      "https://cdn.lr-ingest.io",
      "https://cdn.lr-in.com",
      "https://*.intake-lr.com",
      "https://*.lr-in-prod.com",
    ],
    "connect-src": [
      "'self'",
      backendURL,
      backendURL.replace("http", "ws"),
      authURL,
      "https://*.amazonaws.com",
      "wss://*.localhost:8080",
      "https://api.amplitude.com",
      "https://*.intake-lr.com",
      "https://*.logrocket.io",
      "https://*.lr-ingest.com",
      "https://*.lr-ingest.io",
      "https://*.logrocket.com",
      "https://*.lr-in.com",
      "https://*.lr-in-prod.com",
      "*.amplitude.com",
      "*.amazoncognito.com",
    ],
    "style-src": ["'self'", "'unsafe-inline'"],
    "img-src": ["'self'", "data:", "blob:"],
    "font-src": ["'self'"],
    "worker-src": ["'self'", "blob:"],
    "child-src": ["'self'", "blob:"],
    "frame-src": ["'self'"],
    "object-src": ["'self'"],
  };

  return Object.entries(csp)
    .map(([directive, list]) =>
      [directive, list.join(" ")].join(" ").concat(";"),
    )
    .join(" ");
};
