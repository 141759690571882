import React from "react";
import {Box, Grid, List, Typography} from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined";
import DynamicFeedOutlinedIcon from "@mui/icons-material/DynamicFeedOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FolderSpecialOutlinedIcon from "@mui/icons-material/FolderSpecialOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import PartyModeOutlinedIcon from "@mui/icons-material/PartyModeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import SettingsInputAntennaOutlinedIcon from "@mui/icons-material/SettingsInputAntennaOutlined";
import SettingsInputComponentOutlinedIcon from "@mui/icons-material/SettingsInputComponentOutlined";
import SettingsInputHdmiOutlinedIcon from "@mui/icons-material/SettingsInputHdmiOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import {useFeatureFlags} from "../../context/FeatureFlagProvider";
import {useSidebar} from "./Context/SidebarProvider";
import {useUser} from "../user/Context/UserProvider";
import {useOrgSettings} from "../../context/OrgSettingsProvider";
import SidebarEntry from "./SidebarEntry";
import SidebarMenu from "./SidebarMenu";
import {useSidebarContentStyles} from "./sidebarStyles";

export const SidebarContent = () => {
  const {myActionCount, unreadData} = useSidebar();
  const {user} = useUser();
  const {responseWorkflowName} = useOrgSettings();
  const {showDeveloper} = useFeatureFlags();
  const {classes} = useSidebarContentStyles();

  const isAdmin = user?.hasAdminRole;

  const respondItems = (
    <SidebarMenu icon={<DoubleArrowOutlinedIcon />} text="Respond">
      <SidebarEntry
        to="/actions/list"
        icon={<ListAltIcon />}
        text="My Actions"
        badge={myActionCount?.actions.length}
      />
      <SidebarEntry
        to={`/${responseWorkflowName.lower}s/list`}
        icon={<AssignmentLateOutlinedIcon />}
        text={`${responseWorkflowName.upper}s`}
        relatedModelName="Incident"
      />
      <SidebarEntry
        to="/reports/response"
        icon={<DescriptionOutlinedIcon />}
        text="Response Reports"
      />
      <SidebarEntry
        to="/evidence/pending"
        icon={<Inventory2OutlinedIcon />}
        text="Pending Evidence"
        relatedModelName="Incident"
      />
    </SidebarMenu>
  );

  const sourceItems = (
    <SidebarMenu icon={<LayersOutlinedIcon />} text="Sources" hide={!isAdmin}>
      <SidebarEntry
        to="/obligations/list"
        icon={<PublicOutlinedIcon />}
        text="Obligations"
        relatedModelName="Obligation"
      />
      <SidebarEntry
        to="/tasks/list"
        icon={<AssignmentOutlinedIcon />}
        text="Task Library"
        relatedModelName="Task"
      />
      <SidebarEntry
        to="/playbooks/list"
        icon={<LibraryBooksOutlinedIcon />}
        text="Playbooks"
        relatedModelName="Playbook"
      />
      <SidebarEntry
        to="/datasets/list"
        icon={<StorageOutlinedIcon />}
        text="Datasets"
      />
      <SidebarEntry
        to="/dataOptions/conditions/list"
        icon={<DynamicFeedOutlinedIcon />}
        text="Data Options"
      />
    </SidebarMenu>
  );

  const reportItems = (
    <SidebarMenu icon={<AssessmentOutlinedIcon />} text="Reports">
      <SidebarEntry
        to="/reports/assessment"
        icon={<ErrorOutlineOutlinedIcon />}
        text="Risk Assessment"
      />
      <SidebarEntry
        to="/reports/summary"
        icon={<PieChartOutlinedIcon />}
        text={`${responseWorkflowName.upper}s Summary`}
      />
      <SidebarEntry
        to="/reports/auditlog"
        icon={<AccessTimeOutlinedIcon />}
        text={`${responseWorkflowName.upper}s Audit Log`}
        hide={!isAdmin}
      />
    </SidebarMenu>
  );

  const complianceItems = (
    <SidebarMenu icon={<AssignmentTurnedInOutlinedIcon />} text="Compliance">
      <SidebarEntry
        to="/compliance/summary"
        icon={<FolderSpecialOutlinedIcon />}
        text="Audit Summary"
      />
      <SidebarEntry
        to="/compliance/plan"
        icon={<PolicyOutlinedIcon />}
        text="Response Plan"
      />
    </SidebarMenu>
  );

  const settingItems = (
    <>
      <SidebarMenu
        icon={<SettingsOutlinedIcon />}
        text="Settings"
        startOpen
        hide={!isAdmin}
      >
        <SidebarEntry
          to="/users/list"
          icon={<PersonOutlinedIcon />}
          text="Users"
          relatedModelName="User"
        />
        <SidebarEntry
          to="/roles/list"
          icon={<PeopleAltOutlinedIcon />}
          text="Roles"
          relatedModelName="Role"
        />
        <SidebarEntry
          to="/organization/general"
          icon={<BusinessOutlinedIcon />}
          text="Organization"
          relatedModelName="OrganizationSetting"
        />
        <SidebarEntry
          to="/notifications"
          icon={<SettingsInputAntennaOutlinedIcon />}
          text="Notifications"
        />
      </SidebarMenu>
    </>
  );

  const integrationItems = (
    <SidebarMenu
      icon={<SettingsInputHdmiOutlinedIcon />}
      text="Integrations"
      startOpen
      hide={!isAdmin}
    >
      <SidebarEntry
        to="/integrations"
        icon={<SettingsInputComponentOutlinedIcon />}
        text="Technologies"
        hide={!isAdmin}
      />
      <SidebarEntry
        to="/api/list"
        icon={<VpnKeyOutlinedIcon />}
        text="API Keys"
        relatedModelName="AuthKey"
      />
    </SidebarMenu>
  );

  const fringeBenefitItems = (
    <>
      <SidebarEntry
        to="/messaging/list"
        icon={<MailOutlineIcon />}
        text="Messages"
        badge={unreadData?.userMessages.length}
      />
      <SidebarEntry
        to="/playground"
        icon={<PartyModeOutlinedIcon />}
        text="Playground"
        hide={!showDeveloper}
      />
      <SidebarEntry to="/news" icon={<MenuBookOutlinedIcon />} text="News" />
    </>
  );

  const AdminSeperator = () => (
    <Grid container justifyContent="center" className={classes.container}>
      <Box p={1}>
        <Typography>Admin</Typography>
      </Box>
    </Grid>
  );

  return (
    <List className={classes.list}>
      <SidebarEntry
        to="/dashboard"
        icon={<DashboardOutlinedIcon />}
        text="Dashboard"
      />
      {respondItems}
      {complianceItems}
      {reportItems}
      {fringeBenefitItems}
      {isAdmin && <AdminSeperator />}
      {sourceItems}
      {settingItems}
      {integrationItems}
    </List>
  );
};
