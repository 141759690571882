import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Alerts from "./components/Alerts";
import ErrorBoundary from "./components/ErrorBoundary";
import Main from "./components/Main";
import AlertProvider from "./context/AlertProvider";
import {AuthzProvider} from "./context/AuthzProvider";
import {ConsolidatedThemeProvider} from "./context/ConsolidatedThemeProvider";
import {FeatureFlagProvider} from "./context/FeatureFlagProvider";
import {GraphQLClientProvider} from "./context/GraphQLClientProvider";
import {IdleTimerProvider} from "./context/IdleTimerProvider";
import {OrgSettingsProvider} from "./context/OrgSettingsProvider";
import {ThemeWrapperProvider, useTheme} from "./context/ThemeWrapperProvider";
import {AuthProvider} from "./components/authentication/Context/AuthProvider";
import {NetworkErrorProvider} from "./components/errors/Context/NetworkErrorProvider";
import {SidebarProvider} from "./components/sidebar/Context/SidebarProvider";
import {TourProvider} from "./components/tour/Context/TourProvider";
import {UserProvider} from "./components/user/Context/UserProvider";
import {Sidebar} from "./components/sidebar/Sidebar";
import {Topbar} from "./components/topbar/Topbar";
import {
  consoleTagline,
  initializeDayJS,
  initializeDOMPurify,
} from "./resources/environment";
import {initializeMetrics} from "./resources/metrics";
import Routes from "./Routes";

const App = () => {
  return (
    <ThemeWrapperProvider>
      <AppContent />
    </ThemeWrapperProvider>
  );
};

const AppContent = () => {
  const {activeTheme} = useTheme();

  useEffect(() => {
    console.log(consoleTagline);
    initializeDayJS();
    initializeDOMPurify();
    initializeMetrics();
  }, []);

  return (
    <FeatureFlagProvider>
      <NetworkErrorProvider>
        <ConsolidatedThemeProvider activeTheme={activeTheme}>
          <AuthProvider>
            <GraphQLClientProvider>
              <UserProvider>
                <AuthzProvider>
                  <OrgSettingsProvider>
                    <Router>
                      <CssBaseline />
                      <AlertProvider>
                        <Alerts />
                        <IdleTimerProvider>
                          <TourProvider>
                            <SidebarProvider>
                              <Topbar />
                              <Sidebar />
                              <Main>
                                <ErrorBoundary>
                                  <Routes />
                                </ErrorBoundary>
                              </Main>
                            </SidebarProvider>
                          </TourProvider>
                        </IdleTimerProvider>
                      </AlertProvider>
                    </Router>
                  </OrgSettingsProvider>
                </AuthzProvider>
              </UserProvider>
            </GraphQLClientProvider>
          </AuthProvider>
        </ConsolidatedThemeProvider>
      </NetworkErrorProvider>
    </FeatureFlagProvider>
  );
};

export default App;
