import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useAuthz} from "./context/AuthzProvider";
import {useUser} from "./components/user/Context/UserProvider";
import {useOrgSettings} from "./context/OrgSettingsProvider";
import IntegrationRoutes from "./pages/Integrations";

const AsyncLandingPage = lazy(() => import("./pages/LandingPage/LandingPage"));
const AsyncLandingPageNew = lazy(
  () => import("./pages/LandingPage/LandingPageNew"),
);
const AsyncMyActionsPage = lazy(() => import("./pages/MyActions"));
const AsyncIncidentRoutes = lazy(() => import("./pages/Incidents"));
const AsyncObligationRoutes = lazy(() => import("./pages/Obligations"));
const AsyncTaskRoutes = lazy(() => import("./pages/Tasks"));
const AsyncPlaybookRoutes = lazy(() => import("./pages/Playbooks"));
const AsyncPlayground = lazy(() => import("./pages/Playground/Playground"));
const AsyncReports = lazy(() => import("./pages/Reports"));
const AsyncMessaging = lazy(() => import("./pages/Messaging"));
const AsyncNews = lazy(() => import("./pages/News/News"));
const AsyncSchemaRoutes = lazy(() => import("./pages/Schema"));
const AsyncUserRoutes = lazy(() => import("./pages/Users"));
const AsyncRoleRoutes = lazy(() => import("./pages/Roles"));
const AsyncOrganization = lazy(() => import("./pages/Organization"));
const AsyncNotifications = lazy(() => import("./pages/Notifications"));
const AsyncDatasets = lazy(() => import("./pages/Datasets"));
const AsyncApiKeys = lazy(() => import("./pages/ApiKeys"));
const AsyncUserProfile = lazy(() => import("./pages/UserProfile/UserProfile"));
const AsyncComplianceRoutes = lazy(() => import("./pages/Compliance"));
const AsyncEvidenceRoutes = lazy(() => import("./pages/Evidence"));

const Routes = () => {
  const ability = useAuthz();
  const {user} = useUser();
  const {responseWorkflowName} = useOrgSettings();
  const isAdmin = user?.hasAdminRole;

  const canEditSchema =
    ability.can("update", "ObligationType") ||
    ability.can("update", "IncidentType") ||
    ability.can("update", "TaskType") ||
    ability.can("update", "IncidentSeverity") ||
    ability.can("update", "IncidentPhase") ||
    ability.can("update", "ActionPhase");

  const storedRedirectPath = window.sessionStorage.getItem("redirectPath");
  if (window.location.pathname === storedRedirectPath) {
    window.sessionStorage.removeItem("redirectPath");
  }
  const redirectPath = storedRedirectPath || "/dashboard";

  return (
    <Suspense fallback={null}>
      <Switch>
        <Redirect exact from="/" to={redirectPath} />
        <Route path="/dashboardold" component={AsyncLandingPage} />
        <Route path="/dashboard" component={AsyncLandingPageNew} />
        <Route path="/actions" component={AsyncMyActionsPage} />
        {ability.can("update", "Incident") && (
          <Route
            path={[`/${responseWorkflowName.lower}s`, "/workflow"]}
            component={AsyncIncidentRoutes}
          />
        )}
        {ability.can("update", "Obligation") && (
          <Route path="/obligations" component={AsyncObligationRoutes} />
        )}
        {ability.can("update", "Task") && (
          <Route path="/tasks" component={AsyncTaskRoutes} />
        )}
        {ability.can("update", "Playbook") && (
          <Route path="/playbooks" component={AsyncPlaybookRoutes} />
        )}
        <Route path="/evidence" component={AsyncEvidenceRoutes} />
        <Route path="/playground" component={AsyncPlayground} />
        <Route path="/reports" component={AsyncReports} />
        <Route path="/messaging" component={AsyncMessaging} />
        <Route path="/news" component={AsyncNews} />
        <Route path="/compliance" component={AsyncComplianceRoutes} />,
        {canEditSchema && (
          <Route
            path={["/dataOptions", "/data", "/schema"]}
            component={AsyncSchemaRoutes}
          />
        )}
        {isAdmin && ability.can("update", "User") && (
          <Route path="/users" component={AsyncUserRoutes} />
        )}
        {ability.can("update", "Role") && (
          <Route path="/roles" component={AsyncRoleRoutes} />
        )}
        {isAdmin && [
          <Route
            path="/organization"
            key="organization"
            component={AsyncOrganization}
          />,
          <Route
            path="/notifications"
            key="notifications"
            component={AsyncNotifications}
          />,
          <Route path="/datasets" key="datasets" component={AsyncDatasets} />,
          <Route
            path="/integrations"
            key="integrations"
            component={IntegrationRoutes}
          />,
          <Route path="/api" key="api" component={AsyncApiKeys} />,
        ]}
        <Route path="/userProfile" component={AsyncUserProfile} />
        <Route path="/compliance" component={AsyncComplianceRoutes} />
        <Redirect push to={redirectPath} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
