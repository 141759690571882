import React, {lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Integrations from "./Integrations.jsx";

const GoogleIntegration = lazy(
  () => import("./IntegrationViews/GoogleIntegration"),
);
const JiraIntegration = lazy(
  () => import("./IntegrationViews/JiraIntegration"),
);
const MSEntraIntegration = lazy(
  () => import("./IntegrationViews/MSEntraIntegration"),
);
const MSTeamsIntegration = lazy(
  () => import("./IntegrationViews/MSTeamsIntegration"),
);
const OktaIntegration = lazy(
  () => import("./IntegrationViews/OktaIntegration"),
);
const OneLoginIntegration = lazy(
  () => import("./IntegrationViews/OneLoginIntegration"),
);
const OpsgenieIntegration = lazy(
  () => import("./IntegrationViews/OpsgenieIntegration"),
);
const PagerDutyIntegration = lazy(
  () => import("./IntegrationViews/PagerDutyIntegration"),
);
const PingIntegration = lazy(
  () => import("./IntegrationViews/PingIntegration"),
);
const XsoarIntegration = lazy(
  () => import("./IntegrationViews/PaloAltoCortexXSOARIntegration"),
);
const RevelstokeIntegration = lazy(
  () => import("./IntegrationViews/RevelstokeIntegration"),
);
const SalesforceIntegration = lazy(
  () => import("./IntegrationViews/SalesforceIntegration"),
);
const SlackIntegration = lazy(
  () => import("./IntegrationViews/SlackIntegration"),
);
const VantaIntegration = lazy(
  () => import("./IntegrationViews/VantaIntegration"),
);
const ZapierIntegration = lazy(
  () => import("./IntegrationViews/ZapierIntegration"),
);
const ZoomIntegration = lazy(
  () => import("./IntegrationViews/ZoomIntegration"),
);

const IntegrationRoutes = () => {
  return (
    <Switch>
      <Route path="/integrations/google" component={GoogleIntegration} />
      <Route path="/integrations/jira" component={JiraIntegration} />
      <Route path="/integrations/msentra" component={MSEntraIntegration} />
      <Route path="/integrations/msteams" component={MSTeamsIntegration} />
      <Route path="/integrations/okta" component={OktaIntegration} />
      <Route path="/integrations/onelogin" component={OneLoginIntegration} />
      <Route path="/integrations/opsgenie" component={OpsgenieIntegration} />
      <Route path="/integrations/pagerduty" component={PagerDutyIntegration} />
      <Route path="/integrations/ping" component={PingIntegration} />
      <Route path="/integrations/xsoar" component={XsoarIntegration} />
      <Route
        path="/integrations/revelstoke"
        component={RevelstokeIntegration}
      />
      <Route
        path="/integrations/salesforce"
        component={SalesforceIntegration}
      />
      <Route path="/integrations/slack" component={SlackIntegration} />
      <Route path="/integrations/vanta" component={VantaIntegration} />
      <Route path="/integrations/zapier" component={ZapierIntegration} />
      <Route path="/integrations/zoom" component={ZoomIntegration} />
      <Route path="/integrations" component={Integrations} />
      <Redirect push to="/dashboard" />
    </Switch>
  );
};

export default IntegrationRoutes;
