import Cookies from "js-cookie";
import {isEmpty} from "underscore";

export const cookieAttributes = (expiration) => ({
  expires: expiration,
  secure: true,
  sameSite: "strict",
});

export const getCookieSession = () => {
  const accessToken = Buffer.from(
    Cookies.get("accessToken") ?? "",
    "base64",
  ).toString();
  const idToken = Buffer.from(
    Cookies.get("idToken") ?? "",
    "base64",
  ).toString();
  const refreshToken = Buffer.from(
    Cookies.get("refreshToken") ?? "",
    "base64",
  ).toString();
  const orgName = Buffer.from(
    Cookies.get("orgName") ?? "",
    "base64",
  ).toString();
  const username = Buffer.from(
    Cookies.get("username") ?? "",
    "base64",
  ).toString();

  return (
    !isEmpty(accessToken) &&
        !isEmpty(idToken) &&
        !isEmpty(refreshToken) &&
        !isEmpty(orgName) &&
        !isEmpty(username)
  ) ?
    {
      AccessToken: accessToken,
      IdToken: idToken,
      RefreshToken: refreshToken,
      orgName,
      username,
    } :
    null;
};

export const setCookieSession = (session) => {
  if (!session) return;

  try {
    const accessTokenEncoded = Buffer.from(session.AccessToken).toString(
      "base64",
    );
    const idTokenEncoded = Buffer.from(session.IdToken).toString("base64");
    const refreshTokenEncoded = Buffer.from(session.RefreshToken).toString(
      "base64",
    );
    const orgNameEncoded = Buffer.from(session.orgName).toString("base64");
    const usernameEncoded = Buffer.from(session.username).toString("base64");

    Cookies.set("accessToken", accessTokenEncoded, cookieAttributes(30));
    Cookies.set("idToken", idTokenEncoded, cookieAttributes(30));
    Cookies.set("refreshToken", refreshTokenEncoded, cookieAttributes(30));
    Cookies.set("orgName", orgNameEncoded, cookieAttributes(30));
    Cookies.set("username", usernameEncoded, cookieAttributes(30));
  } catch (error) {
    // ignore silently
  }
};

export const clearCookieSession = () => {
  Cookies.remove("accessToken");
  Cookies.remove("idToken");
  Cookies.remove("refreshToken");
  Cookies.remove("orgName");
  Cookies.remove("username");
};
